import React, { ReactNode } from "react"
import { Container } from "@mui/material"
import NavBar from "./NavBar"

const GeneralRouter = ({ children }: { children: ReactNode }) => {
  return (
    <div id="app" className="d-flex flex-column h-100">
      <NavBar />
      <Container className="flex-grow-1 mt-5">
        {children}
      </Container>
    </div>
  )
}

export default GeneralRouter