import React, { useEffect } from "react";
import useUserStore from "../store/useUserStore.ts";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const QRLanding = () => {
  const { qrCodeId }: { qrCodeId: string } = useParams();
  const { id } = useUserStore()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (id && qrCodeId) {
        window.location.replace(`/user/check-in/${qrCodeId}`);
      } else {
        window.location.replace(`/user/authenticate/${qrCodeId}`);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [id, qrCodeId])
  
  return <CircularProgress />

}
export default QRLanding;
