import React, { useEffect, useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";

import useAdminUser from "../hooks/useAdminUser.ts";
import Logo from '../assets/logo.svg';
import useUserStore from "../store/useUserStore.ts";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import useUser from "../hooks/useUser.ts";
import { Typography } from "@mui/material";


const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalLogout, setModalLogout] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const toggleModal = () => setModal(!modal);
  const toggleModalLogout = () => setModalLogout(!modalLogout);
  const { user: userAdmin } = useAdminUser();
  const { id: userId, setUserId } = useUserStore();
  const { user: customerUser } = useUser()
  const { qrCodeId } = useParams()


  const handleLogout = () => {
    setUserId(null);
    window.location.replace(`/qr-landing/${qrCodeId}`);
    toggleModal();
  };

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md" container={false}>
        <Container>
          <img src={Logo} height={50} style={{ marginRight: 40, marginLeft: 40 }} />
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink
                  style={{padding: 0}}
                  tag={RouterNavLink}
                  to="/"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Home
                </NavLink>
              </NavItem>
            </Nav>
            <Nav className="d-none d-md-block" navbar>
              <Container style={{display: 'flex', flexDirection: 'row', gap: 10}}>
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => window.location.replace('/user/authenticate')}
                  >
                    Usuario
                  </Button>
                </NavItem>
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => window.location.replace('/business/')}
                  >
                    Comercios
                  </Button>
                </NavItem>
              </Container>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Account</ModalHeader>
        <ModalBody>
          <Typography>Name: {customerUser?.name}</Typography>
          <Typography>Email: {customerUser?.email}</Typography>
          <Typography>Phone Number: {customerUser?.phoneNumber}</Typography>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModalLogout}>Logout</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Back</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalLogout} toggle={toggleModalLogout}>
        <ModalHeader toggle={toggleModalLogout}>Confirm Logout</ModalHeader>
        <ModalBody>
          Are you sure you want to log out?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleLogout}>Yes</Button>{' '}
          <Button color="secondary" onClick={toggleModalLogout}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NavBar;
