import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button, CircularProgress, Alert, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import useAdminUser from '../hooks/useAdminUser.ts';

interface AddVisitModalProps {
    open: boolean;
    onClose: () => void;
    userId: string;
}

const AddVisitModal: React.FC<AddVisitModalProps> = ({ open, onClose, userId }) => {
    const [visitDate, setVisitDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { getAccessTokenSilently } = useAuth0();
    const [selectedLocation, setSelectedLocation] = useState('');
    const { user: adminUserInfo } = useAdminUser();
    const [success, setSuccess] = useState(false);

    const handleAddVisit = async () => {
        setLoading(true);
        setError(null);
        try {
            const accessToken = await getAccessTokenSilently();
            const request = await axios.post(`${process.env.REACT_APP_API_URL}/checkin/manual`, {
                userId,
                locationId: selectedLocation,
                visitDate,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (request.data.status === 'ok') {
                setSuccess(true)
            }
            setTimeout(() => {
                onClose();
                setSuccess(false);
                setLoading(false);
            }, 2000);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        } 
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    {success && <Alert severity="success" sx={{ display: success ? 'block' : 'none', marginBottom: 2 }}>Visita agregada con éxito</Alert>}
                    <Typography variant="h6" gutterBottom>
                        Agregar Visita
                    </Typography>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="location-select-label">Seleccionar Ubicación</InputLabel>
                        <Select
                            label="Seleccionar Ubicación"
                            variant='outlined'
                            labelId="location-select-label"
                            value={selectedLocation}
                            onChange={(e) => setSelectedLocation(e.target.value)}
                        >
                            {adminUserInfo?.adminInformation?.locations.map((location) => (
                                <MenuItem key={location._id} value={location._id}>
                                    {location.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Fecha de Visita"
                        type="datetime-local"
                        value={visitDate}
                        onChange={(e) => setVisitDate(e.target.value)}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {loading && <CircularProgress />}
                    {error && <Alert severity="error">{error}</Alert>}
                    <Button variant="contained" color="primary" onClick={handleAddVisit} disabled={loading}>
                        Agregar
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default AddVisitModal;
