import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, CircularProgress, Alert, TextField, Button, FormControl, InputLabel, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import useAdminUser from '../../hooks/useAdminUser.ts';
import BusinessWrapper from '../../components/BusinessWrapper.tsx';
import AddVisitModal from '../../components/AddVisit.tsx';

interface UserMetrics {
    totalCheckIns: number;
    lastCheckIn: string;
    userDetails: {
        name: string;
        phoneNumber: string;
    };
    userId: string;
}

const CheckinUserDashboard = () => {
    const [data, setData] = useState<UserMetrics[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [timesCheckedIn, setTimesCheckedIn] = useState<number | ''>('');
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const { getAccessTokenSilently } = useAuth0();
    const { user: adminUserInfo } = useAdminUser();
    const [selectedLocation, setSelectedLocation] = useState<string>('');
    const [selectedUserId, setSelectedUserId] = useState<string>('');
    const [addVisitModalOpen, setAddVisitModalOpen] = useState(false);

    const fetchData = async (options?: { isLoadMore: true }) => {
        if (!selectedLocation || !startDate || !endDate) return;
        setLoading(true);
        setError(null);
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/checkin/users`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                params: {
                    locationId: selectedLocation,
                    startDate,
                    endDate,
                    offset,
                    limit,
                    timesCheckedIn,
                }
            });
            if (response.data.status !== 'ok') {
                throw new Error(response.data.message);
            } else {
                if (options?.isLoadMore) {
                    setData(data => [...data, ...response.data.data]);
                } else {
                    setData(response.data.data);
                }
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleFetchData = () => {
        setOffset(0); // Reset offset when fetching new data
        fetchData();
    };

    const handleLoadMore = () => {
        setOffset(prevOffset => prevOffset + limit);
    };

    useEffect(() => {
        fetchData({ isLoadMore: true });
    }, [offset]);

    return (
        <BusinessWrapper>
            <Typography variant="h4" gutterBottom>
                Usuarios
            </Typography>
            <div>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="location-select-label">Seleccionar Ubicación</InputLabel>
                    <Select
                        label="Seleccionar Ubicación"
                        variant='outlined'
                        labelId="location-select-label"
                        value={selectedLocation}
                        onChange={(e) => setSelectedLocation(e.target.value)}
                    >
                        {adminUserInfo?.adminInformation?.locations.map((location) => (
                            <MenuItem key={location._id} value={location._id}>
                                {location.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label="Fecha de Inicio"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="Fecha de Fin"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="Cantidad de visitas en el periodo"
                    type="number"
                    value={timesCheckedIn}
                    onChange={(e) => setTimesCheckedIn(e.target.value === '' ? '' : Number(e.target.value))}
                    fullWidth
                    margin="normal"
                />
                <Button variant="contained" color="primary" onClick={handleFetchData}>
                    Buscar
                </Button>
            </div>
            {loading && <CircularProgress />}
            {error && <Alert severity="error">{error}</Alert>}
            {data?.length > 0 && (
                <TableContainer component={Paper} style={{ marginTop: 50, marginBottom: 30 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Número de Teléfono</TableCell>
                                <TableCell>Visitas totales</TableCell>
                                <TableCell>Última Visita</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((user) => (
                                <TableRow key={user.userId}>
                                    <TableCell><Link href={`/business/customer/${user.userId}`}>{user.userDetails.name}</Link></TableCell>
                                    <TableCell>{user.userDetails.phoneNumber}</TableCell>
                                    <TableCell>{user.totalCheckIns}</TableCell>
                                    <TableCell>{new Date(user.lastCheckIn).toLocaleString()}</TableCell>
                                    <TableCell>
                                        <Button variant="contained" color="primary" onClick={() => {
                                            setSelectedUserId(user.userId);
                                            setAddVisitModalOpen(true);
                                        }}>
                                            Agregar Visita
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {data?.length > 0 && (
                <Button variant="contained" color="primary" onClick={handleLoadMore}>
                    Cargar Más
                </Button>
            )}
            <AddVisitModal open={addVisitModalOpen} userId={selectedUserId} onClose={() => setAddVisitModalOpen(false)}/>
        </BusinessWrapper>
    );
};

export default CheckinUserDashboard;