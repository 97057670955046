import React from "react"
import CampaignInformation from "../../module/CampaignInformation.tsx"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import useAdminUser from "../../hooks/useAdminUser.ts"
import BusinessWrapper from "../../components/BusinessWrapper.tsx"

const CustomerCheckins = () => {
  const { customerId } = useParams();
  const { user, loading } = useAdminUser();
  const qrCodeId = user?.adminInformation?.locations[0]?.qrCodeId; // Come back to this later, take the qrCodeId from the admin information instead, this causes problems as some locations do not yet have a qrCodeId assigned

  if (!loading && !qrCodeId) {
    return (
    <BusinessWrapper>
      <h3>No se encontró la ubicación del usuario</h3>
    </BusinessWrapper>
    )
  }else if(!qrCodeId){
    return null
  }

  return (
    <BusinessWrapper>
      <CampaignInformation qrCodeId={qrCodeId} userId={customerId} withUserInfo />
    </BusinessWrapper>
  )
}

export default CustomerCheckins