import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface UserState {
    id: string|undefined;
    setUserId: (phoneNumber: string) => void;
}

const useUserStore = create(
    persist<UserState>(
        (set) => ({
            id: undefined,
            setUserId: (id: string) => set({ id }),
        }),
        {
            name: 'user-storage',
            getStorage: () => localStorage,
        }
    )
);

export default useUserStore