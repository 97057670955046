import React, {type ReactNode} from "react"
import NavBarCustomer from "./NavBarCustomer"
import { Container } from "@mui/material"

const CustomerWrapper = ({children}: {children: ReactNode}) => {
    return (
        <div id="app" className="d-flex flex-column h-100">
            <NavBarCustomer />
            <Container className="flex-grow-1 mt-5">
                {children}
            </Container>
        </div>
    )
}

export default CustomerWrapper