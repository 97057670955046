import React, { useEffect, useState } from "react";
import axios from "axios";
import useUserStore from "../store/useUserStore.ts";
import { Card, CardContent, Typography, Container, Grid, Box, CircularProgress, Link } from "@mui/material";
import CustomerWrapper from "../components/CustomerWrapper.tsx";

interface BusinessDetails {
  _id: string;
  name: string;
  friendlyName: string;
  cashbackPercentage: number;
  redeemCodes: any[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  logo: string;
}

interface LocationDetails {
  _id: string;
  business: string;
  name: string;
  address: string;
  codes: any[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  businessDetails: BusinessDetails;
  qrCodeId: string;
}

interface Metric {
  _id: string;
  lastCheckIn: string;
  totalCheckIns: number;
  locationDetails: LocationDetails;
}

interface MetricsResponse {
  status: string;
  message: string;
  data: Metric[];
}

const HomeUser = () => {
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { id: userId } = useUserStore();

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await axios.get<MetricsResponse>(`${process.env.REACT_APP_API_URL}/checkin/user/metrics`, {
          params: { userId }
        });
        if (response.data.status === "ok") {
          setMetrics(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching metrics:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, [userId]);

  if (userId == null) {
    return <Typography variant="h5" textAlign={'center'}>Inicia sesión para obtener recompensas en diferentes comercios.</Typography>
  }

  return (
    <CustomerWrapper>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Tus Visitas
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : metrics.length > 0 ? (
          <Grid container spacing={4}>
            {metrics.map((metric) => (

              <Grid item xs={12} sm={12} md={12} key={metric._id}>
                <Link href={`/user/dashboard/${metric.locationDetails.qrCodeId}`}>
                  <Card>
                    <CardContent>
                      <Box display="flex" alignItems="center">
                        <Box flexShrink={0} mr={2}>
                          <img src={metric.locationDetails.businessDetails.logo} alt={metric.locationDetails.businessDetails.name} style={{ height: 80 }} />
                        </Box>
                        <Box>
                          <Typography variant="h6" component="h2">
                            {metric.locationDetails.name}
                          </Typography>
                          <Typography color="textSecondary">
                            Dirección: {metric.locationDetails.address}
                          </Typography>
                          <Typography color="textSecondary">
                            Visitas totales: {metric.totalCheckIns}
                          </Typography>
                          <Typography color="textSecondary">
                            Ultima Visita: {new Date(metric.lastCheckIn).toLocaleString()}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>Aquí verás la información de tus visitas a los comercios, escanea el QR de celer y obtén recompensas!</Typography>
        )}
      </Box>
    </CustomerWrapper>
  );
};

export default HomeUser;