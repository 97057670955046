import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Link } from '@mui/material';
import GeneralRouter from '../components/GeneralWrapper.tsx';

const FAQ: React.FC = () => {
    return (
        <GeneralRouter>
            <Typography variant="h4" gutterBottom>Preguntas frecuentes Celer</Typography>

            <Typography variant="h6" gutterBottom>¿Por qué el sistema me pide que me autentique si ya me había autenticado anteriormente?</Typography>
            <Typography paragraph>Para evitar el proceso de volver autenticarte después de tu primera visita, te recomendamos seguir estos pasos:</Typography>
            <List>
                <ListItem>
                    <ListItemText primary="Utiliza el mismo navegador en tu dispositivo que usaste al registrarte." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="No uses el modo incógnito o privado en tu navegador para poder almacenar los datos de tu usuario sin necesidad de autenticarte cada vez que escanees el QR." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="No borres las cookies de tu dispositivo." />
                </ListItem>
            </List>
            <Typography paragraph>Si por error realizaste alguna de estas acciones, no perderás las visitas acumuladas; simplemente tendrás que volver a autenticarte para vincular tu número de teléfono con tu usuario.</Typography>

            <Typography variant="h6" gutterBottom>¿Qué es Celer?</Typography>
            <Typography paragraph>Celer es una herramienta de marketing para comercios omnicanal y "offline". Facilitamos la comunicación efectiva con los clientes a través de WhatsApp y nuestros canales digitales. Los mantenemos informados para que puedan aprovechar las promociones de manera efectiva y brindar un servicio al cliente instantáneo.</Typography>

            <Typography variant="h6" gutterBottom>¿Qué tengo que hacer para dejar de recibir mensajes del comercio por WhatsApp?</Typography>
            <Typography paragraph>Tienes dos opciones para dejar de recibir mensajes:</Typography>
            <List>
                <ListItem>
                    <ListItemText primary='Presiona la opción "Dejar de recibir mensajes" en el último mensaje que se te envió por Whatsapp.' />
                </ListItem>
                <ListItem>
                    <ListItemText primary='En el menú, selecciona la opción "Darse de baja". (no está disponible por el momento)' />
                </ListItem>
            </List>

            <Typography variant="h6" gutterBottom>¿Qué tengo que hacer para darme de alta y recibir mensajes nuevamente?</Typography>
            <Typography paragraph>Para volver a recibir mensajes, debes:</Typography>
            <List>
                <ListItem>
                    <ListItemText primary='Escanear el QR y marcar la casilla "Acepto recibir promociones de marketing".' />
                </ListItem>
            </List>

            <Typography variant="h6" gutterBottom>¿Qué pasa si se me olvida escanear el QR en una de mis visitas?</Typography>
            <Typography paragraph>Informa al administrador que no escaneaste el QR durante una de tus visitas anteriores. Él revisará con el sistema de reservaciones el número de visitas acumuladas y lo actualizará en el sistema de Celer.</Typography>

            <Typography variant="h6" gutterBottom>Contacto</Typography>
            <Typography paragraph>Si tienes alguna otra pregunta, mándanos un correo a <Link href="mailto:principal@usaceler.com">principal@usaceler.com</Link>.</Typography>
        </GeneralRouter>
    );
};

export default FAQ;
