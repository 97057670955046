import { useState, useEffect } from 'react';
import axios from 'axios';
import useUserStore from '../store/useUserStore.ts';

interface User {
    user: {
        _id: string;
        name: string;
        phoneNumber: string;
        email: string;
    }
    // Add other user properties here
}

const useUser = () => {
    const [user, setUser] = useState<User['user'] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { id: userId } = useUserStore();
    useEffect(() => {
        const fetchUser = async () => {
            try {
                if(userId == null) return;
                const response = await axios.get<User>(`${process.env.REACT_APP_API_URL}/user/`, {
                    params: {
                        userId,
                    }
                });
                setUser(response.data.user);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [userId]);

    return { user, loading, error };
};

export default useUser;