import React, { Container } from "@mui/material";
import { ReactNode } from "react";
import NavBarBusiness from "./NavBarBusiness";

const BusinessWrapper = ({children}: {children: ReactNode}) => {
    return (
      <div id="app" className="d-flex flex-column h-100">
        <NavBarBusiness />
        <Container className="flex-grow-1 mt-5">
          {children}
        </Container>
      </div>
    );
  }

export default BusinessWrapper