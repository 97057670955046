import axios from 'axios';

interface RewardDocument {
    // Define the structure of RewardDocument based on your schema
    _id: string
    campaign: string
    name: string
    code: string
    crteria: string
    criteriaValue: number
    description: string
}

interface RewardResponse {
    status: string;
    message: string;
    data: {
        businessName: string;
        rewards: { reward: RewardDocument; earned: boolean, timesBeforeEarning: number }[]
    } | null;
}

export interface GetRewardResponse {
    data: RewardResponse | null;
    error: string | null;
}
export const getRewards = async (userId: string, qrCodeId: string): Promise<GetRewardResponse> => {
    try {
        const response = await axios.get<RewardResponse>(`${process.env.REACT_APP_API_URL}/rewards/`, {
            params: { userId, qrCodeId },
        });
        return { data: response.data, error: null };
    } catch (err) {
        return { data: null, error: err.message };
    }
};