import React, { useState, useEffect } from 'react';
import Joi from 'joi';
import { Alert, Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useUserStore from '../../store/useUserStore.ts';
import { useQueryParams } from '../../hooks/useQueryParams.ts';
import CustomerWrapper from '../../components/CustomerWrapper.tsx';

const VerifyWhatsapp: React.FC = () => {
  const { qrCodeId } = useParams();
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const { setUserId } = useUserStore();
  let query = useQueryParams();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setError(null);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
    setError(null);
  };

  const handleVerificationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(e.target.value);
    setError(null);
  };

  const handleSendCode = () => {
    setIsLoading(true);
    const phoneSchema = Joi.string().pattern(/^\d{1,14}$/).required();
    const nameSchema = Joi.string().pattern(/^[A-Za-z]+(?:\s[A-Za-z]+)+$/).min(5).max(30).required();

    const phoneValidation = phoneSchema.validate(phoneNumber);
    const nameValidation = nameSchema.validate(name);

    if (phoneValidation.error) {
      setError('Número de teléfono no válido, recuerda no incluir espacios, guiones ni código de país');
      setIsLoading(false);
      return;
    }

    if (nameValidation.error) {
      setError('Nombre no valido, debe tener al menos 5 caracteres y no contener numeros');
      setIsLoading(false);
      return;
    }

    setError(null);
    const completePhoneNumber = phoneNumber === '3154572727' ? '573154572727' : `${process.env.REACT_APP_PHONE_INDICATIVE}${phoneNumber}`;
    axios.post(`${process.env.REACT_APP_API_URL}/auth/send-auth-code`, { WaId: completePhoneNumber })
      .then(response => {
        console.log(response);
        setIsLoading(false);
        if (response.data.status === 'ok') {
          setIsCodeSent(true);
          setCountdown(45); // Start the countdown
        }
      })
      .catch(error => {
        console.error(error);
        setError('Error sending verification code');
        setIsLoading(false);
      });
  };

  const handleVerifyCode = () => {
    let referer = query.get('referer');
    const completePhoneNumber = `${process.env.REACT_APP_PHONE_INDICATIVE}${phoneNumber}`;
    axios.post(`${process.env.REACT_APP_API_URL}/auth/verify-auth-code`, { WaId: completePhoneNumber, code: verificationCode, name, referer })
      .then(response => {
        console.log(response);
        setIsLoading(false);
        if (response.data.status === 'ok') {
          setUserId(response.data.userId);
          if (qrCodeId?.length > 0) {
            window.location.replace(`/user/check-in/${qrCodeId}`);
          }
          setIsCodeVerified(true);
        } else {
          setError('Código de verificación no válido');
        }
      })
      .catch(error => {
        console.error(error);
        setError('Error verifying code');
        setIsLoading(false);
      });
  };

  return (
    <CustomerWrapper>
      <Grid justifyContent="center">
        <Typography variant="h4" textAlign={'center'}>Inicia sesión y obtén recompensas</Typography>
        <TextField
          style={{ marginTop: '30px' }}
          label="Nombre Completo"
          fullWidth
          margin="normal"
          value={name}
          onChange={handleNameChange}
          disabled={isCodeSent}
        />
        <TextField
          style={{ marginTop: '30px' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">+{process.env.REACT_APP_PHONE_INDICATIVE}</InputAdornment>,
          }}
          label="Número de WhatsApp"
          fullWidth
          margin="normal"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          disabled={isCodeSent}
        />
        <FormControlLabel
          control={<Checkbox checked disabled />}
          label="Al continuar y verificar tu número a través de WhatsApp, confirmas que estás de acuerdo en recibir promociones por este medio. Puedes darte de baja en cualquier momento."
        />
        <Box>
          {error && <Alert severity="error">{error}</Alert>}
        </Box>
        {isCodeSent && (
          <TextField
            style={{ marginTop: '30px' }}
            label="Código de Verificación"
            fullWidth
            margin="normal"
            value={verificationCode}
            onChange={handleVerificationCodeChange}
          />
        )}
        <Box display='flex' flexDirection='column'>
          {(isCodeVerified && !qrCodeId) && <Redirect to='/user/' />}
          <Button
            style={{ alignSelf: 'center', marginTop: '30px' }}
            variant="contained"
            color="primary"
            onClick={handleSendCode}
            disabled={isLoading || countdown > 0}
          >
            {isCodeSent ? (
              countdown > 0 ? `Reenviar Código (${countdown})` : 'Reenviar Código'
            ) : 'Enviar Código'}
            {isLoading && <CircularProgress size={24} />}
          </Button>
          <Button
            style={{ alignSelf: 'center', marginTop: '15px' }}
            variant="contained"
            color="primary"
            onClick={handleVerifyCode}
            disabled={!isCodeSent}
          >Verificar codigo</Button>
        </Box>
      </Grid>
    </CustomerWrapper>
  );
};

export default VerifyWhatsapp;
