import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, Typography, Container, Box, Alert, Backdrop, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import useUserStore from '../../store/useUserStore.ts';
import useUser from '../../hooks/useUser.ts';
import { CheckinResponse } from '../../interfaces/general.ts';
import CampaignInformation from '../../module/CampaignInformation.tsx';
import CustomerWrapper from '../../components/CustomerWrapper.tsx';

const CheckIn: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [checkInResponse, setCheckInResponse] = useState<CheckinResponse | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { qrCodeId } = useParams();
  const { id: userId } = useUserStore();
  const { user } = useUser();

  const handleCheckIn = (userId: string, qrCodeId: string) => {
    setIsLoading(true);

    axios.post<CheckinResponse>(`${process.env.REACT_APP_API_URL}/checkin/`, { userId, qrCodeId })
      .then(response => {
        setCheckInResponse(response.data);
        setIsLoading(false);
        setIsModalOpen(true); // Show the modal upon successful check-in
      })
      .catch(error => {
        setError('Error verifying phone number');
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (userId && qrCodeId) {
      handleCheckIn(userId, qrCodeId);
    }
  }, [qrCodeId, userId]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (!isLoading && checkInResponse?.status !== 'ok') {
    return <Alert severity="error">{checkInResponse?.message}</Alert>;
  }

  return (
    <CustomerWrapper>
      <Backdrop open={isLoading} style={{ zIndex: 1000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container justifyContent='space-between'>
        <Box mt={4} mb={4}>
          <Typography variant="h4" gutterBottom>
            {checkInResponse?.data?.businessName}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {user?.name}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Visitas registradas: {checkInResponse?.data?.checkinTimes}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Visitas aprobadas: {checkInResponse?.data?.checkinsApproved == 0 ? "Esperando aprobación de tu primera visita" : `${checkInResponse?.data?.checkinsApproved}`}
          </Typography>
        </Box>
        <Box>
          {checkInResponse?.data?.businessLogo && (
            <img src={checkInResponse?.data?.businessLogo} alt="Banner" style={{ width: 300 }} />
          )}
        </Box>
      </Grid>
      {checkInResponse?.data?.currentReward && (
        <Box mb={4}>
          <Alert>
            <Typography variant="h5" gutterBottom>
              Hoy ganaste: {checkInResponse?.data?.currentReward?.name}!
            </Typography>
            <Typography variant="h6" gutterBottom>
              Codigo de Cupón: <span style={{ fontWeight: 700 }}>{checkInResponse?.data?.currentReward.code}</span>
            </Typography>
          </Alert>
        </Box>
      )}
      {(qrCodeId && userId) && <CampaignInformation qrCodeId={qrCodeId} userId={userId} />}
      <Box mt={4} display="flex" justifyContent="center">
        {error && <Alert severity="error">{error}</Alert>}
      </Box>

      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Se ha registrado tu visita</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Has registrado tu visita en {checkInResponse?.data?.businessName}.
          </DialogContentText>
          <DialogContentText>
            <span style={{ fontWeight: 700 }}>En un momento se aprobará tu visita</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </CustomerWrapper>
  );
};

export default CheckIn;