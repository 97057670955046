import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";

import Logo from '../assets/logo.svg';
import useUserStore from "../store/useUserStore.ts";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import { Link, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import useUser from "../hooks/useUser.ts";


const NavBarCustomer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalLogout, setModalLogout] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const toggleModal = () => setModal(!modal);
  const toggleModalLogout = () => setModalLogout(!modalLogout);
  const { user: customerUser } = useUser()
  const { setUserId } = useUserStore();
  const { qrCodeId } = useParams()


  const handleLogout = () => {
    setUserId(null);
    window.location.replace(qrCodeId ? `/qr-landing/${qrCodeId}` : '/user/authenticate');
    toggleModal();
  };

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md" container={false}>
        <Container>
          <img src={Logo} height={50} style={{ marginRight: 40, marginLeft: 40 }} />
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Home
                </NavLink>
              </NavItem>
            </Nav>
            {customerUser ? (
              <Nav className={isOpen ? 'mr-auto' : "d-none d-md-block"} navbar>
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={toggleModal}
                  >
                    {customerUser?.name} | Mi Cuenta
                  </Button>
                </NavItem>
              </Nav>
            ) : (
              <Nav className={isOpen ? 'mr-auto' : "d-none d-md-block"} navbar>
                <NavItem>
                  <Link href='/user/authenticate'>
                    <Button
                      id="qsLoginBtn"
                      color="primary"
                      className="btn-margin"
                    >
                      Iniciar Sesión
                    </Button>
                  </Link>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
      <Dialog open={modal} onClose={toggleModal} fullWidth>
        <DialogTitle>Información de tu cuenta</DialogTitle>
        <DialogContent>
          <Typography>Nombre: {customerUser?.name}</Typography>
          <Typography>Teléfono: {customerUser?.phoneNumber}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={toggleModalLogout}>Cerrar Sesión</Button>
          <Button color="secondary" onClick={toggleModal}>Atrás</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={modalLogout} onClose={toggleModalLogout}>
        <DialogTitle>Cerrar sesión</DialogTitle>
        <DialogContent>
          ¿Estás seguro de que quieres cerrar sesión?
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleLogout}>Si</Button>
          <Button color="secondary" onClick={toggleModalLogout}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NavBarCustomer;
