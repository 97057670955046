import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { AdminUserInterface } from "../interfaces/business.interface";

const useAdminUser = () => {
  const [stateUser, setStateUser] = useState<AdminUserInterface|null>(null)
  const [loading, setLoading] = useState(true)
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  async function getAdminUser() {
    if (isAuthenticated && user != null && user.sub) {
      const accessToken = await getAccessTokenSilently()
      const requestUserDB = await fetch(`${process.env.REACT_APP_API_URL}/user/sub`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      })
      const dbUser = await requestUserDB.json()
      setStateUser(dbUser.user)
      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(() => {
     void getAdminUser()
  }, [isAuthenticated])

  return {
    user: stateUser,
    loading,
  }  
}

export default useAdminUser