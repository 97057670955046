import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useAdminUser from '../../hooks/useAdminUser.ts';
import { Button, CircularProgress, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Link } from '@mui/material';
import axios from 'axios';
import { ICheckin, IGetAllCheckinsResponse } from '../../interfaces/general.ts';
import { formatDateToLocal } from '../../utils/date.ts';
import BusinessWrapper from '../../components/BusinessWrapper.tsx';

const CheckinManagement = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { user: adminUserInfo, loading } = useAdminUser();
  const [checkins, setCheckins] = useState<ICheckin[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const defaultDate = new Date().toString()
  const [selectedDate, setSelectedDate] = useState<string>(defaultDate);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [countdown, setCountdown] = useState<number>(10);

  const fetchCheckins = async () => {
    if (!selectedLocation || !selectedDate) return;
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get<IGetAllCheckinsResponse>(`${process.env.REACT_APP_API_URL}/checkin/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          locationId: selectedLocation,
          date: new Date(selectedDate),
        },
      });
      setCheckins(response.data.data);
    } catch (error) {
      console.error('Error al obtener los check-ins:', error);
    } finally {
      setIsLoading(false);
      setCountdown(10); // Reset countdown after fetching
    }
  };

  const approveCheckin = async (checkinId: string) => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      await axios.post(`${process.env.REACT_APP_API_URL}/checkin/approve`, {
        checkinId,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      fetchCheckins()
    } catch (error) {
      console.error('Error al aprobar el check-in:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedLocation || !selectedDate) return;
    fetchCheckins();
    const interval = setInterval(fetchCheckins, 10000);
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 10));
    }, 1000);
    return () => {
      clearInterval(interval);
      clearInterval(countdownInterval);
    };
  }, [selectedLocation, selectedDate]);

  const filteredCheckins = checkins.filter((checkin) =>
    checkin.user?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (adminUserInfo && !loading && user) {
    return (
      <BusinessWrapper>
        <Typography variant="h4" gutterBottom marginBottom={4}>Visitas</Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel id="location-select-label">Seleccionar Sucursal</InputLabel>
          <Select
            label="Seleccionar Sucursal"
            variant='outlined'
            labelId="location-select-label"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
          >
            {adminUserInfo.adminInformation?.locations.map((location) => (
              <MenuItem key={location?._id} value={location?._id}>
                {location?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Seleccionar Fecha"
          type="date"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={defaultDate}
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
        <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={2}>
          <Button variant="contained" color="primary" onClick={fetchCheckins} disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : 'Buscar'}
          </Button>
          {isLoading && <CircularProgress size={24} />}
        </Box>

        <>
          <Typography variant="subtitle1" gutterBottom marginTop={6}>Buscar por nombre de cliente</Typography>
          <TextField
            label="Buscar Cliente por Nombre"
            type="text"
            fullWidth
            margin="normal"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Typography variant="body2" color="grey" marginTop={2}>
            Actualizando en: {countdown} segundos
          </Typography>
          {filteredCheckins.length > 0 ? (
            <TableContainer component={Paper} style={{ marginTop: '16px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Usuario</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Acción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCheckins.map((checkin) => (
                    <TableRow key={checkin._id} style={checkin.verificationStatus === 'APPROVED' ? { backgroundColor: '#89fa9e' } : { backgroundColor: 'white' }}>
                      <TableCell>{checkin.user.name}</TableCell>
                      <TableCell>{formatDateToLocal(checkin.createdAt)}</TableCell>
                      <TableCell>
                        {
                          checkin.verificationStatus === 'APPROVED'
                            ? <span style={{ fontWeight: 900 }}>Aprobado</span> : (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => approveCheckin(checkin._id)}
                                disabled={isLoading}
                              >
                                {isLoading ? <CircularProgress size={24} /> : 'Aprobar'}
                              </Button>
                            )
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="h6">No se encontraron intentos de registro de Visita.</Typography>
          )}
        </>
      </BusinessWrapper>
    );
  }

  if (!loading && !adminUserInfo) {
    return (
      <BusinessWrapper>
        <Typography variant="h4" gutterBottom>
          Inicia sesión para ingresar al panel de administración
        </Typography>
        <Link href='/business/register'>
          <Button variant="contained" color="primary">
            Iniciar Sesión
          </Button>
        </Link>
      </BusinessWrapper>
    );
  }
};

export default CheckinManagement;
