import React, { useEffect } from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import { Button } from "reactstrap";
import { handleRegisterUser } from "../../services/user.ts";
import BusinessWrapper from "../../components/BusinessWrapper.tsx";

const BusinessSignup = () => {
  const { getAccessTokenSilently, isAuthenticated, loginWithPopup, user } = useAuth0();


  useEffect(() => {
    async function registerUser() {
      const accessToken = await getAccessTokenSilently()
      if (isAuthenticated) {
        handleRegisterUser(accessToken, user as User)
      }
    }
    void registerUser()
  }, [isAuthenticated])

  if (isAuthenticated) {
    return (
      <BusinessWrapper>
        <h3 style={{ textAlign: 'center' }}>Gracias por registrarte.</h3>
        <p style={{ textAlign: 'center' }}>Enlazaremos tu cuenta con tu negocio para que puedas gestionar las visitas de tus clientes de manera eficiente.</p>
      </BusinessWrapper>
    )
  }

  return (
    <BusinessWrapper>
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ textAlign: 'center' }}>Fideliza a tus clientes con Celer</h1>
        <p style={{ textAlign: 'center' }}>Registrate y empieza a conocer y a fidelizar a tus clientes. Obten estadisticas y comunicate con tus clientes directamente por whatsapp!</p>
        <Button
          id="qsLoginBtn"
          color="primary"
          className="btn-margin"
          onClick={() => loginWithPopup()}
        >
          Registrate
        </Button>
      </div>
    </BusinessWrapper>
  )


}

export default BusinessSignup