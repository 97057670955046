import React from "react"
import { Box, CircularProgress, Container, Typography } from "@mui/material"
import useUser from "../../hooks/useUser.ts"
import CampaignInformation from "../../module/CampaignInformation.tsx"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import CustomerWrapper from "../../components/CustomerWrapper.tsx"

const UserDashboard = () => {
  const { qrCodeId } = useParams();
  const { user, loading } = useUser();

  if(loading){
    return (
      <CustomerWrapper>
        <Box mt={4}>
          <CircularProgress/>
        </Box>
      </CustomerWrapper>
    )
  }
  
  if(user?._id == null) {
    return (
      <CustomerWrapper>
        <Box mt={4}>
          <Typography variant="h4" gutterBottom>
            No se encontró información del usuario
          </Typography>
        </Box>
      </CustomerWrapper>
    )
  }
  return (
    <CustomerWrapper>
      <CampaignInformation qrCodeId={qrCodeId} userId={user?._id }/>
    </CustomerWrapper>
  )
}

export default UserDashboard