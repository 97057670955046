import React from "react";
import { Router, Route, Switch } from "react-router-dom";

import Loading from "./components/Loading";
import { ProfileComponent } from "./views/Profile";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import QRLanding from "./views/QRLanding.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import VerifyWhatsapp from "./views/user/VerifyWhatsapp.tsx";
import CheckIn from "./views/user/CheckIn.tsx";
import CheckinManagement from "./views/admin/CheckinManagement.tsx";
import BusinessSignup from "./views/admin/SignupLanding.tsx";
import CheckinUserDashboard from "./views/admin/CheckinUsersDashboard.tsx";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min.js";
import HomeUser from "./views/HomeUser.tsx";
import Home from "./views/Home.tsx";
import UserDashboard from "./views/user/UserDashboard.tsx";
import CustomerCheckins from "./views/admin/CustomerCheckins.tsx";
import PrivacyPolicy from "./views/PrivacyPolicy.tsx";
import FAQ from "./views/FAQ.tsx";

initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();

  const queryClient = new QueryClient()

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/qr-landing/:qrCodeId" exact component={QRLanding} />
          <Route path="/privacy" exact component={PrivacyPolicy} />
          <Route path="/faq" exact component={FAQ} />
          <Route path={`/user/`} exact component={HomeUser} />
          <Route path={`/user/authenticate`} exact component={VerifyWhatsapp} />
          <Route path={`/user/authenticate/:qrCodeId`} component={VerifyWhatsapp} />
          <Route path={`/user/check-in/:qrCodeId`} component={CheckIn} />
          <Route path={`/user/dashboard/:qrCodeId`} component={UserDashboard} />
          <Redirect from={`/business/`} exact to={`/business/checkin-management`} />
          <Route path={`/business/checkin-management`} component={CheckinManagement} />
          <Route path={`/business/checkin-dashboard`} component={CheckinUserDashboard} />
          <Route path={`/business/profile`} component={ProfileComponent} />
          <Route path={`/business/customer/:customerId`} component={CustomerCheckins} />
          <Route path={`/business/register`} component={BusinessSignup} />
        </Switch>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
