import React, { useEffect, useState } from "react";
import useUserStore from "../store/useUserStore.ts";
import { Button, Container, Link, Typography, CircularProgress, Box, Grid, Paper } from "@mui/material";
import useAdminUser from "../hooks/useAdminUser.ts";
import GeneralRouter from "../components/GeneralWrapper.tsx";

const Home = () => {
  const { id: userId } = useUserStore();
  const { user: adminUser } = useAdminUser();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (adminUser?.user?._id != null) {
      window.location.replace('/admin/checkin-dashboard');
    } else if (userId != null && userId !== "") {
      window.location.replace('/user/');
    } else {
      setLoading(false);
    }
  }, [adminUser, userId]);

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <GeneralRouter>
      <Box textAlign="center" mt={5}>
        <Typography variant="h4" component="h1" gutterBottom>
          Bienvenido a Celer
        </Typography>
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom>
                  Usuarios
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Obtén recompensas en tus comercios favoritos
                </Typography>
                <Link href="/user/authenticate" style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="primary" size="large" style={{ marginTop: '10px' }}>
                    Usuario
                  </Button>
                </Link>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom>
                  Comercios
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Programa de lealtad digital y WhatsApp marketing
                </Typography>
                <Link href="/business" style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="primary" size="large" style={{ marginTop: '10px' }}>
                    Comercios
                  </Button>
                </Link>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </GeneralRouter>
  );
};

export default Home;