import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, CircularProgress, Box } from '@mui/material';
import { GetRewardResponse, getRewards } from '../services/rewards.ts';
import { Container } from '@mui/material';
import { getUser } from '../services/user.ts';
import { UserInterface } from '../interfaces/user.interface.ts';

const CampaignInformation = ({ qrCodeId, userId, withUserInfo = false }: { qrCodeId: string, userId: string, withUserInfo?: boolean }) => {
  const [rewards, setRewards] = useState<GetRewardResponse['data'] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [customerInfo, setCustomerInfo] = useState<UserInterface|null>(null);

  async function handleSetRewards(userId: string, qrCodeId: string) {
    const rewardsResponse = await getRewards(userId, qrCodeId);
    setRewards(rewardsResponse.data);
    setIsLoading(false)
  }

  async function handleGetUserInfo(userId: string) {
    setCustomerInfo((await getUser(userId)).user)
  }
  useEffect(() => {
    if (userId) {
      handleGetUserInfo(userId)
      handleSetRewards(userId, qrCodeId)
    }
  }, [userId, qrCodeId]);

  if (isLoading) {
    return (
      <Container>
        <Grid container justifyContent={'center'}>
          <CircularProgress />
        </Grid>
      </Container>
    )
  }

  return (
    <Container>
      {withUserInfo && (
        <Box mb={6}>
          <Typography variant="h5" gutterBottom>Información del Usuario</Typography>
          <Typography variant="body1" gutterBottom>Nombre: {customerInfo?.name}</Typography>
          <Typography variant="body1" gutterBottom>Telefono: {customerInfo?.phoneNumber}</Typography>
        </Box>
      )}
      <Typography variant="h5" gutterBottom mb={2}>Tabla de Recompensas: {rewards?.data?.businessName}</Typography>
      <Grid container spacing={3} justifyContent="center">
        {rewards?.data?.rewards?.map((reward) => (
          <Grid item key={reward.reward._id} xs={12} sm={6} md={4}>
            <Card
              style={{
                backgroundColor: reward.earned ? '#e0ffe0' : '#ffe0e0',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <CardContent>
                <Typography variant="h5" component="div">
                  {reward.reward.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {reward.reward.description}
                </Typography>
                {reward.timesBeforeEarning === 0 ? (
                  <>
                    <Typography variant="body2" color="text.secondary">
                      ¡Felicidades! Has ganado este premio
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Reclamalo con el codigo: {reward.reward.code}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    Visitas pendientes para obtener: {reward.timesBeforeEarning}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CampaignInformation;