import { User } from "@auth0/auth0-react"
import axios from "axios"
import { UserInterface } from "../interfaces/user.interface"

export const handleRegisterUser = async (accessToken: string, user: User) => {
  const request = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      auth0Id: user.sub,
      firstName: user?.given_name ?? user.email,
      lastName: user?.family_name ?? user.email,
      email: user.email,
    })
  })
  const response = await request.json()
  console.log('Response', response)
  return response
}

export const getUser = async (userId: string): Promise<{user: UserInterface, ok: boolean}> => {
  const request = await axios.get(`${process.env.REACT_APP_API_URL}/user`, {
    params: { userId },
  })
  const response = request.data
  return response
}