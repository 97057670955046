import React from 'react';
import { Box, Typography } from '@mui/material';
import GeneralRouter from '../components/GeneralWrapper.tsx';

const PrivacyPolicy: React.FC = () => {
  return (
    <GeneralRouter>
      <Box sx={{ padding: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          Política de Privacidad de Celer
        </Typography>
        <Typography variant="body1" paragraph>
          En Celer, nos comprometemos a proteger la privacidad de los datos personales de nuestros clientes. Esta política de privacidad describe cómo recopilamos, utilizamos y protegemos la información que nos proporcionas al acceder a nuestros servicios a través de terceros o nuestra plataforma web. Al utilizar nuestros servicios, aceptas las prácticas descritas en esta política.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Información que recopilamos
        </Typography>
        <Typography variant="body1" paragraph>
          Cuando te registras para recibir mensajes de marketing a través de WhatsApp utilizando terceros o nuestro código QR en la sucursal, recopilamos los siguientes datos personales:
          <ul>
            <li>Nombre completo</li>
            <li>Número de teléfono</li>
            <li>Dirección de correo electrónico</li>
            <li>Información relacionada con la reserva realizada</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Cómo utilizamos tu información
        </Typography>
        <Typography variant="body1" paragraph>
          La información que recopilamos se utiliza para los siguientes propósitos:
          <ul>
            <li>Enviar mensajes de marketing relevantes a través de WhatsApp sobre los negocios en los que has realizado reservas.</li>
            <li>Informarte sobre ofertas especiales, actualizaciones y otra información útil relacionada con los servicios que ofrecemos.</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Protección de datos
        </Typography>
        <Typography variant="body1" paragraph>
          Implementamos medidas de seguridad físicas, técnicas y organizativas adecuadas para proteger tus datos personales contra la destrucción accidental o ilegal, la pérdida, las alteraciones, la divulgación o el acceso no autorizado. Utilizamos herramientas y servicios de seguridad avanzados para garantizar la integridad y confidencialidad de tus datos. Además, realizamos copias de seguridad periódicas para asegurar la recuperación rápida en caso de incidentes.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Retención de datos
        </Typography>
        <Typography variant="body1" paragraph>
          Conservaremos tus datos personales solo durante el tiempo necesario para cumplir con los fines para los que fueron recopilados, a menos que la ley requiera un período de retención más largo. Una vez que ya no sean necesarios, tus datos serán eliminados de manera segura.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Derechos del usuario
        </Typography>
        <Typography variant="body1" paragraph>
          Tienes derecho a acceder, rectificar, cancelar u oponerte al tratamiento de tus datos personales en cualquier momento. También puedes revocar tu consentimiento para recibir mensajes de marketing a través de WhatsApp haciendo clic en el enlace de "Darse de baja" incluido en cada mensaje.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Transferencia de datos
        </Typography>
        <Typography variant="body1" paragraph>
          No compartiremos tus datos personales con terceros para fines de marketing. Cualquier transferencia de datos a proveedores de servicios que nos ayuden a procesar la información se realizará bajo estrictas medidas de seguridad y conforme a las leyes de protección de datos aplicables.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Cookies y tecnologías similares
        </Typography>
        <Typography variant="body1" paragraph>
          Utilizamos cookies y tecnologías similares para mejorar tu experiencia al utilizar nuestros servicios. Estas tecnologías nos ayudan a comprender cómo interactúas con nuestro sitio web y nos permiten ofrecerte contenido personalizado. Puedes gestionar tus preferencias sobre cookies en la configuración de tu navegador.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Incidentes y notificaciones
        </Typography>
        <Typography variant="body1" paragraph>
          En caso de cualquier incidente relacionado con la seguridad de tus datos personales, te informaremos oportunamente sobre el mismo y tomaremos las medidas necesarias para mitigar cualquier posible impacto. También te invitamos a reportar cualquier actividad sospechosa o violación a nuestras políticas.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Contacto
        </Typography>
        <Typography variant="body1" paragraph>
          Si tienes alguna pregunta o inquietud sobre nuestra política de privacidad o el tratamiento de tus datos personales, no dudes en ponerte en contacto con nuestro equipo enviando un correo electrónico a principal@usaceler.com
        </Typography>

        <Typography variant="body2" color="textSecondary" paragraph>
          Esta política de privacidad puede ser actualizada periódicamente. Te recomendamos revisarla regularmente para estar informado sobre cualquier cambio.
        </Typography>
      </Box>
    </GeneralRouter>
  );
};

export default PrivacyPolicy;
